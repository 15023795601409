#main_content {
  padding: 2%;
  text-align: center;
}

#logo {
  width: min( 100px, 40% );
}

#pic112 {
  max-height: min(100px, 20%);
  max-width: min(100px, 20%);
}

#show_more {
  margin: auto;
  display: inline-block;
}
