.back_button .icon_logout_button,
.icon_login_button {
  background: white; /*var(--ion-color-light-shade);*/
  color: var(--ion-color-primary);
  border-radius: 20px;
  width: 50px;
  height: 50px;
  --padding-end: 5px;
  --padding-start: 5px;
}

.icon_logout_button > ion-icon,
.icon_login_button > ion-icon,
.back_button > ion-icon {
  font-size: 40px;
  color: var(--ion-color-primary);
}

#icon_container {
  padding-top: 20px;
  width: 100%;
  text-align: center;
}

#person_icon {
  color: var(--ion-color-primary);
  font-size: min( 20vw, 100px );
}

.authentication_error {
  color: var(--ion-color-danger);
}

/* DESKTOP VERSION */

.desktop-version{

  & ion-tab-bar{
    display: none;
  }

  & #authentication_tab_bars{
    display: block;
    text-align: center;
  }

  & .authentication-nav-button{
    display: inline-flex;
    padding: 5px 20px 5px 20px;
    color: var(--ion-color-medium);
    text-align: center;
    cursor: pointer;
  }

  & .authentication-nav-button:hover{
    color:var(--ion-color-primary);
  }

  & .authentication-nav-button>ion-icon{
    height: 25px;
    width: 22px;
    padding-top: 3px;
  }

  & .authentication-nav-button>ion-label{
    padding: 5px;
  }

  & .authentication-nav-button-current{
    color: var(--ion-color-primary)
  }

  & #authentication_header_buttons{
    position:absolute;
    left: 20px
  }

  & #authentication_login_buttons{
    position:absolute;
    right: 20px
  }
}