ion-content ion-button {
  color: white;
  width: calc( min( 200px, 80% ) );
  margin-left: calc( max( 50% - 100px, 10% ) );
}

.center_button_icon {
  margin-left: calc(50% - 15px);
}

.button_icon,
.button_icon_centered,
.button_icon_danger,
.button_icon_light,
.button_icon_ok {
  width: 30px;
  height: 30px;
  --border-radius: 15px;
  --padding-start: 5px;
  --padding-end: 5px;
}

.button_icon_danger,
.button_danger {
  --background: var(--ion-color-danger);
}

.button_icon_light {
  --background: var(--ion-color-medium);
}

.button_icon_ok {
  --background: var(--ion-color-success-shade);
}

.button_icon_centered{
  margin-left: calc( 50% - 15px );
}

.button_icon ion-icon,
.button_icon_danger ion-icon,
.button_icon_centered ion-icon,
.button_icon_light ion-icon,
.button_icon_ok ion-icon {
  color: var(--ion-color-light);
  width: 30px;
  height: 30px;
}

.with_padding {
  padding-left: 5%;
  padding-right: 5%;
}

.page_content {
  --padding-top: var(--main-padding);
  --padding-start: var(--main-padding);
  --padding-end: var(--main-padding);
}

#main_page_background{
  /* position: fixed;
  z-index: -1; */
  min-height: 100%;
  width: 100%;
  background-image: url("../resources/background2.png") ;
  background-repeat: repeat-y;
  background-size: 100vw;
}

#main_page_content {
  margin-left: max( 0px, calc( 50vw - 600px ));
  width: min( 100vw , 1200px);
  min-height: var(--screen-height);
  background-color: var(--ion-color-secondary-contrast);
  padding: var(--main-padding);
}

ion-item {
  --padding-start: 0px;
  width: 100%;
}

/* Desktop */


.desktop-version{
  
  & ion-tab-bar{
    background-color: var(--ion-color-light);
  }

  & ion-tab-button{
    flex-flow: row;
    background-color: var(--ion-color-light);
  }
  
  & ion-tab-bar ion-label{
    padding: 10px 0 0 10px;
    font-size: larger;
  }

  & .page_content {
    --padding-top: 20px;
    --padding-start: 20px;
    --padding-end: 20px;
  }

  & ion-modal{
    --height: min( 100vh , 700px);
    --width: min( 100vw , 500px );
  }
}