#datepicker {
  width: 100%;
}

.geolocationItem {
  width: 100%;
}

.intervention-edit-sub{
  width: 80%;
  font-size: 14px;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

.intervention-remove-sub{
  --background: var(--ion-color-danger-shade);
  color: var(--ion-color-secondary-contrast);
  margin-left: 1%;
  margin-top: 4px;
  /*font-size: 28px;*/
}

.subdescription,
.subdescription0 {
  font-style: italic;
  color: var(--ion-color-medium);
  margin: 0px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 10%;
}

.subdescription {
  color: var(--ion-color-primary);
}

.defaultSize{
  width:unset;
  height:unset;
}

#analysis_location_string{
  text-align: left;
}

#edit_intervention_content ion-item{
  --padding-bottom: 2vw;
}

.intervention-item-content, .measurement-item-content, .analysis-item-content{
  display: block;
  width: 100%;
}

.block_div{
  display: block;
  width:100%;
}

.intervention_analysis_coord_label1{
  margin: 0px;
  text-align: right;
  font-size: small;
}
.intervention_analysis_coord_label2{
  margin: 0px;
  text-align: left;
  font-size: small;
}
#intervention_analysis_coord_field1{
  text-align: right;
}
#intervention_analysis_coord_field2{
  text-align: left;
}