.alert_card_left {
  display: inline-block;
  width: calc(95% - 80px);
}

.alert_card_right {
  width: 80px;
  display: inline-block;
  text-align: end;
}

.alert_card_right ion-button {
  display: inline-block;
}

.not_sent_alert_button {
  display: inline-block;
  --background: var(--ion-color-medium);
  width: 40px;
  height: 40px;
  --border-radius: 20px;
  --padding-start: 10%;
  --padding-end: 10%;
}

.sent_alert_icon,
.not_sent_alert_icon {
  color: var(--ion-color-light);
  width: 30px;
  height: 30px;
}