#leafletMap {
  height: 100%;
}

#map_container {
  position: relative;
  height: 80%;
  width: 100%;
}

.leaflet-container {
  height: 80%;
}

#locate_icon {
  position: absolute;
  top: calc(40% - 15px);
  z-index: 1000;
  right: calc(50% - 15px);
  width: 30px;
  height: 30px;
  color: red;
}

.geo_locator_icon_button {
  margin-left: calc( ( 100% - 2 * 30px ) / 3 );
  margin-right: 0px;
}

.geo_loc_result {
  margin-left: 10vw;
}

.geo_loc_result > * {
  width: 100%;
}
