.data_menu_button > div {
  text-align: left;
  width: 100%;
}
.data_menu_button > div > * {
  display: inline-block;
  margin-right: 5%;
  vertical-align: middle;
}
.data_menu_button > div > ion-icon {
  width: 30px;
  height: 30px;
}
.leaflet_map_icon {
  background: none;
  border: none;
}

#tabdata_map_page{
  position: relative;
  height: calc( var(--screen-height) - 2 * var(--main-padding) );
  width: 100%;
}

#tabdata_map_container {
  position: relative;
  height: 100%;
  width: 100%;
}

#tabdata_map_container > .leaflet-container {
  height: 100%;
}

.point_location_container {
  position: relative;
  height: 40vh;
  width: 100%;
}

.point_location_container > .leaflet-container {
  height: 40vh;
}

ion-range {
  --pin-color: white;
}

#tabdata_date_div > * {
  display: inline-block;
}

.tabdata_analysis_card {
  border-top: solid 1px var(--ion-color-medium);
  /*background-color: var(--ion-color-light-shade);
  padding: 5px;
  box-shadow: 2px 2px 2px var(--ion-color-medium); */
}

.em_light {
  font-style: italic;
  color: var(--ion-color-medium);
}

.tabdata_file_card {
  display: block;
  color: white;
  background-color: var(--ion-color-primary);
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}


.tabdata-alert-card, .tabdata-intervention-card{
  cursor: pointer;
}

/* Desktop version */
.desktop-version{
 & #tabdata_map_page{
  display: flex;
  flex-direction: row;
 }

 & #tabdata_map_container{
  width: 65%;
 }

 & #tabdata_map_list{
  width: 35%;
  padding-left:1%; 
 }
}
